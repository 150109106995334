import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Language } from '../models/language.model';

@Injectable({
	providedIn: 'root',
})
export class LanguageManagerService {
	private apiUrl = 'api/languages';

	constructor(private http: HttpClient) { }

	getLanguages(): Observable<Language[]> {
		return this.http.get<Language[]>(this.apiUrl);
	}

	getLanguage(id: number): Observable<Language> {
		return this.http.get<Language>(`${this.apiUrl}/${id}`);
	}

	createLanguage(language: Partial<Language>): Observable<Language> {
		return this.http.post<Language>(this.apiUrl, language);
	}

	updateLanguage(id: number, language: Partial<Language>): Observable<Language> {
		return this.http.put<Language>(`${this.apiUrl}/${id}`, language);
	}

	deleteLanguage(id: number): Observable<void> {
		return this.http.delete<void>(`${this.apiUrl}/${id}`);
	}
}
