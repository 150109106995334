import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
	private baseUrl: string = environment.hostname + (environment.port?.length > 0 ? ':' + environment.port : '');

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Check if the request URL is relative (not absolute)
		if (req.url.startsWith('/')) {
			// Prepend the base URL to the request
			const apiReq = req.clone({ url: `${this.baseUrl}${req.url}` });
			return next.handle(apiReq);
		}
		// If the URL is already absolute, pass it through unchanged
		return next.handle(req);
	}
}
