<nz-layout [ngClass]="authService.isAuthenticated() ? 'app-layout' : ''">
	<nz-sider *ngIf="authService.isAuthenticated()" class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
		[nzTrigger]="null">
		<div class="sidebar-logo">
			<a href="/" target="_blank">
				<img src="https://ng.ant.design/assets/img/logo.svg" alt="logo">
				<h1>Recipe Manager</h1>
			</a>
		</div>
		<ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
			<!-- Login -->
			<li nz-menu-item nzMatchRouter>
				<a *ngIf="authService.isAuthenticated()" (click)="logout()">
					<i nz-icon nzType="logout"></i>
					<span *ngIf="!isCollapsed">Logout</span>
				</a>
			</li>

			<!-- Home -->
			<li nz-menu-item nzMatchRouter>
				<a routerLink="/home">
					<i nz-icon nzType="home"></i>
					<span *ngIf="!isCollapsed">Home</span>
				</a>
			</li>

			<li nz-submenu nzOpen nzTitle="Manager" nzIcon="dashboard" *appHasRole="['administrator']">
				<ul>
					
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/manager/roles">Roles</a>
					</li>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/manager/users">Users</a>
					</li>

					<li nz-menu-item nzMatchRouter>
						<a routerLink="/manager/prompts">Prompts</a>
					</li>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/manager/languages">Languages</a>
					</li>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/manager/category/recipe">Recipe categories</a>
					</li>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/manager/category/kitchen">Recipe Kitchens</a>
					</li>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/manager/category/difficulty">Recipe Difficulties</a>
					</li>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/manager/category/page">Page categories</a>
					</li>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/manager/websites">Websites</a>
					</li>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/manager/templates">Templates</a>
					</li>
				</ul>
			</li>

			<li nz-submenu nzOpen nzTitle="Prompt Generator" nzIcon="tool">
				<ul>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/prompt-generator">Prompt Generator</a>
					</li>
				</ul>
			</li>

			<!-- Recepe -->
			<li nz-submenu nzOpen nzTitle="Recipes" nzIcon="coffee">
				<ul>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/recipe/listing">List recipes</a>
					</li>
					<li nz-menu-item nzMatchRouter>
						<a routerLink="/recipe/add">Create new recipe</a>
					</li>
				</ul>
			</li>

		</ul>
	</nz-sider>
	<nz-layout>
		<nz-header *ngIf="authService.isAuthenticated()">
			<div class="app-header">
				<span class="header-trigger" (click)="isCollapsed = !isCollapsed">
					<span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
				</span>
			</div>
		</nz-header>
		<nz-content>
			<div class="inner-content">
				<router-outlet></router-outlet>
			</div>
		</nz-content>
	</nz-layout>
</nz-layout>