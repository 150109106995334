// Frontend: src/app/directives/has-role.directive.ts

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';

@Directive({
	selector: '[appHasRole]',
})
export class HasRoleDirective {
	private roles: string[] = [];

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private authService: AuthService,
	) { }

	@Input()
	set appHasRole(roles: string[]) {
		this.roles = roles;
		this.updateView();
	}

	private updateView(): void {
		if (this.authService.hasRoles(this.roles)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
