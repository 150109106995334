<!-- src/app/components/prompt-tool/prompt-tool.component.html -->

<nz-layout>
	<!-- Top Section: Form -->
	<nz-card>
		<form>
			<ng-container *ngIf="promptKeys.length > 0; else tplPromptKeysNotFound">
				<ng-container *ngFor="let word of promptKeys">
					<nz-form-item>
						<nz-form-label [nzFor]="'link'">{{word.key}}</nz-form-label>
						<nz-form-control [nzErrorTip]="'Please enter a valid {{word.key}}'">
							<input id="{{word.key}}" name="{{word.key}}" nz-input [(ngModel)]="word.value" placeholder="Enter {{word.key}}" (ngModelChange)="updatePromptsDescription()"/>
						</nz-form-control>
					</nz-form-item>
				</ng-container>
			</ng-container>

			<ng-template #tplPromptKeysNotFound>
				Please select a Prompt template
			</ng-template>

			<nz-form-item>
				<nz-form-control>
					<button nz-button nzType="default" type="button" (click)="resetTopForm()">Reset</button>
				</nz-form-control>
			</nz-form-item>
		</form>
	</nz-card>

	<!-- Bottom Section: Prompts -->
	<nz-row [nzGutter]="16" style="margin-top: 16px;">
		<!-- ChatGPT Section -->
		<nz-col nzSpan="12">
			<nz-card title="ChatGPT Prompts">
				<!-- Filters -->
				<nz-row [nzGutter]="16" class="filter-section">
					<nz-col nzSpan="12">
						<nz-select [(ngModel)]="chatGptLanguage" (ngModelChange)="updateChatGptDescription()"
							nzPlaceHolder="Select Language">
							<nz-option *ngFor="let lang of languages" [nzValue]="lang.code"
								[nzLabel]="lang.name"></nz-option>
						</nz-select>
					</nz-col>
				</nz-row>

				<!-- Prompt Buttons -->
				<nz-space nzSize="small" style="margin: 8px 0;">
					<button *ngFor="let prompt of chatGptPrompts; let i = index" nz-button nzType="default"
						(click)="selectChatGptPrompt(prompt)">
						{{ i + 1 }}
					</button>
				</nz-space>
				<nz-divider></nz-divider>

				<!-- Action Buttons -->
				<nz-space nzSize="middle" style="margin-bottom: 8px;">
					<button nz-button nzType="default" (click)="toggleChatGptHashtags(true)">With Hashtags</button>
					<button nz-button nzType="default" (click)="toggleChatGptHashtags(false)">Without Hashtags</button>
				</nz-space>

				<!-- Description Field -->
				<nz-form-item>
					<nz-form-control>
						<textarea nz-input [value]="chatGptDescription" readonly></textarea>
					</nz-form-control>
				</nz-form-item>

				<nz-space nzSize="middle" style="margin-bottom: 8px;">
					<button nz-button nzType="primary" (click)="copyChatGptPrompt()">Copy ChatGPT Prompt</button>
				</nz-space>

			</nz-card>
		</nz-col>

		<!-- Midjourney Section -->
		<nz-col nzSpan="12">
			<nz-card title="Midjourney Prompts">
				<!-- Filters and Add Button -->
				<nz-row [nzGutter]="16" class="filter-section">
					<nz-col nzSpan="8">
						<nz-select [(ngModel)]="midjourneyLanguage" (ngModelChange)="updateMidjourneyDescription()"
							nzPlaceHolder="Select Language">
							<nz-option *ngFor="let lang of languages" [nzValue]="lang.code"
								[nzLabel]="lang.name"></nz-option>
						</nz-select>
					</nz-col>
				</nz-row>

				<!-- Prompt Buttons -->
				<nz-space nzSize="small" style="margin: 8px 0;">
					<button *ngFor="let prompt of midjourneyPrompts; let i = index" nz-button nzType="default"
						(click)="selectMidjourneyPrompt(prompt)">
						{{ i + 1 }}
					</button>
				</nz-space>
				<nz-divider></nz-divider>

				<!-- Description Field -->
				<nz-form-item>
					<nz-form-control>
						<textarea nz-input [value]="midjourneyDescription" readonly></textarea>
					</nz-form-control>
				</nz-form-item>
				
				<!-- Action Buttons -->
				<nz-space nzSize="middle" style="margin-bottom: 8px;">
					<button nz-button nzType="primary" (click)="copyMidjourneyPrompt()">Copy Midjourney Prompt</button>
				</nz-space>
			</nz-card>
		</nz-col>
	</nz-row>
</nz-layout>