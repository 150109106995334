// Frontend: src/app/guards/roles.guard.ts

import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class RolesGuard implements CanActivate {
	constructor(private authService: AuthService, private message: NzMessageService) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| boolean
		| UrlTree
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree> {
			
		const requiredRoles: string[] = route.data['roles'];
		const hasRole = this.authService.hasRoles(requiredRoles);

		if (!hasRole) {
			this.message.error('You do not have permission to access this page.');
		}

		return hasRole;
	}
}
