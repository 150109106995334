// Frontend: src/app/services/user.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role, User } from '../models/user.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private apiUrl = 'api/users'; // Adjust as needed

	constructor(private http: HttpClient) { }

	getUsers(): Observable<User[]> {
		return this.http.get<User[]>(this.apiUrl);
	}

	getUser(id: number): Observable<User> {
		return this.http.get<User>(`${this.apiUrl}/${id}`);
	}

	createUser(user: Partial<User>): Observable<User> {
		return this.http.post<User>(this.apiUrl, user);
	}

	updateUser(id: number, user: Partial<User>): Observable<User> {
		return this.http.put<User>(`${this.apiUrl}/${id}`, user);
	}

	deleteUser(id: number): Observable<void> {
		return this.http.delete<void>(`${this.apiUrl}/${id}`);
	}
}
