import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { User } from '../manager/models/user.model';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

	connectedUser: User | null = null;

	constructor(private authService: AuthService) { }

	ngOnInit(): void {
		this.authService.currentUser$.subscribe(u => this.connectedUser = u);
	}
}
