import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Role, User } from 'src/app/features/manager/models/user.model';
import { UserService } from 'src/app/features/manager/services/user.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private baseUrl = '/auth';
	private tokenKey = 'authToken';
	private userKey = 'user';

	private currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
	public currentUser$: Observable<User | null> = this.currentUserSubject.asObservable();


	constructor(private http: HttpClient, private router: Router, private userService: UserService) { }

	login(username: string, password: string): Observable<{ token: string, user: User }> {
		return this.http.post<{ token: string, user: User }>(`${this.baseUrl}/token`, { username, password }).pipe(map(r => {
			this.setCurrentUser(r.user);
			this.saveToken(r.token);
			return r;
		}));
	}

	logout(): void {
		localStorage.removeItem(this.tokenKey);
		this.clearCurrentUser();
		this.router.navigate(['/login']);
	}

	register(username: string, email: string, password: string): Observable<any> {
		return this.http.post(`${this.baseUrl}/register`, { username, email, password });
	}

	isAuthenticated(): boolean {
		return !!localStorage.getItem(this.tokenKey);
	}

	saveToken(token: string): void {
		localStorage.setItem(this.tokenKey, token);
	}

	getToken(): string | null {
		return localStorage.getItem(this.tokenKey);
	}

	recoverPassword(email: string): Observable<any> {
		return this.http.post(`${this.baseUrl}/recover-password`, { email });
	}

	resetPassword(token: string, newPassword: string): Observable<any> {
		return this.http.post(`${this.baseUrl}/reset-password`, { token, newPassword });
	}

	confirmEmail(token: string): Observable<any> {
		return this.http.get(`${this.baseUrl}/confirm`, { params: { token } });
	}

	setCurrentUser(user: User): void {
		this.currentUserSubject.next(user);
		localStorage.setItem(this.userKey, JSON.stringify(user));
	}

	clearCurrentUser(): void {
		this.currentUserSubject.next(null);
		localStorage.removeItem(this.userKey);
	}

	getUserRoles(): Role[] {
		if (!this.currentUserSubject.value) {
			const userStr = localStorage.getItem(this.userKey);
			if (userStr && (userStr?.length ?? 0 > 0))
				this.currentUserSubject.next(JSON.parse(userStr) as User);
		}
		return this.currentUserSubject.value?.roles || [];
	}

	hasRoles(requiredRoles: string[]): boolean {
		const userRoles = this.getUserRoles();
		if (!userRoles || !requiredRoles) {
			return false;
		}

		return requiredRoles.some((role) =>
			userRoles.some(
				(userRole) =>
					userRole.name.trim().toLowerCase() === role.trim().toLowerCase()
			)
		);
	}
}
