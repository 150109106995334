import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { AuthGuard } from './core/guards/auth.guard';
import { PromptGeneratorComponent } from './features/prompt-generator/prompt-generator.component';
import { RolesGuard } from './core/guards/role.guard';

const routes: Routes = [
	// { path: '', pathMatch: 'full', redirectTo: '/welcome' },
	{ path: '', pathMatch: 'full', redirectTo: 'home' },
	{ path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
	{ path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
	{ path: 'manager', loadChildren: () => import('./features/manager/manager.module').then(m => m.ManagerModule), canActivate: [AuthGuard, RolesGuard], data: {roles: ['administrator']} },
	{ path: 'recipe', loadChildren: () => import('./features/recipe-page/recipe-page.module').then(m => m.RecipePageModule), canActivate: [AuthGuard] },
	{ path: 'prompt-generator', component: PromptGeneratorComponent, canActivate: [AuthGuard] },
	{ path: '**', redirectTo: 'auth/login', pathMatch: 'full' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }