// src/app/services/prompt.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Prompt } from '../models/prompt.model';

@Injectable({
	providedIn: 'root',
})
export class PromptService {
	private apiUrl = 'api/prompts'; // Adjust the URL as needed

	constructor(private http: HttpClient) { }

	getPrompts(): Observable<Prompt[]> {
		return this.http.get<Prompt[]>(this.apiUrl);
	}

	createPrompt(prompt: Prompt): Observable<Prompt> {
		return this.http.post<Prompt>(this.apiUrl, prompt);
	}

	updatePrompt(id: number, prompt: Prompt): Observable<Prompt> {
		return this.http.put<Prompt>(`${this.apiUrl}/${id}`, prompt);
	}

	deletePrompt(id: number): Observable<void> {
		return this.http.delete<void>(`${this.apiUrl}/${id}`);
	}
}
