import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse,
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) { }

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// Get the token from the AuthService
		const authToken = this.authService.getToken();

		let theReq = req;
		if (authToken) {
			theReq = req.clone({
				setHeaders: {
					Authorization: `Bearer ${authToken}`,
				},
			});
		}

		return next.handle(theReq).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.authService.logout();
				}
				return throwError(() => error);
			})
		);
	}
}
