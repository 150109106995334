// src/app/components/prompt-tool/prompt-tool.component.ts

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Language } from '../manager/models/language.model';
import { Prompt } from '../manager/models/prompt.model';
import { LanguageManagerService } from '../manager/services/language-manager.service';
import { PromptService } from '../manager/services/prompt.service';
import { KeyValue } from '@angular/common';

@Component({
	selector: 'app-prompt-generator',
	templateUrl: './prompt-generator.component.html',
	styleUrls: ['./prompt-generator.component.scss']
})
export class PromptGeneratorComponent implements OnInit {
	// Languages
	languages: Language[] = [];

	// ChatGPT Prompts
	chatGptPrompts: Prompt[] = [];
	selectedChatGptPrompt: Prompt | null = null;
	chatGptLanguage: string = '';

	// Midjourney Prompts
	midjourneyPrompts: Prompt[] = [];
	selectedMidjourneyPrompt: Prompt | null = null;
	midjourneyLanguage: string = '';

	// Descriptions
	chatGptDescription: string = '';
	midjourneyDescription: string = '';

	promptKeys: KeyValue<string, string>[] = [];

	constructor(
		private languageService: LanguageManagerService,
		private promptService: PromptService,
		private message: NzMessageService
	) {
	}

	ngOnInit(): void {
		this.loadLanguages();
		this.loadPrompts();
	}

	loadLanguages(): void {
		this.languageService.getLanguages().subscribe({
			next: (langs) => this.languages = langs,
			error: () => this.message.error('Failed to load languages.')
		});
	}

	loadPrompts(): void {
		this.promptService.getPrompts().subscribe({
			next: (prompts) => {
				this.chatGptPrompts = prompts.filter(p => p.promptType === 'ChatGPT');
				this.midjourneyPrompts = prompts.filter(p => p.promptType === 'Midjourney');
			},
			error: () => this.message.error('Failed to load prompts.')
		});
	}

	resetTopForm(): void {
		if(this.promptKeys){
			this.promptKeys.forEach(el => el.value = '');
		}
	}

	// ChatGPT Methods
	selectChatGptPrompt(prompt: Prompt): void {
		this.selectedChatGptPrompt = prompt;
		this.updatePromptKeys();
		this.updateChatGptDescription();
	}
	
	updatePromptsDescription(){
		this.updateChatGptDescription();
		this.updateMidjourneyDescription();
	}

	updatePromptKeys(){
		if(!this.selectedChatGptPrompt?.description && !this.selectedMidjourneyPrompt?.description)
			return;

		let words: string[]= [];
		if(this.selectedChatGptPrompt?.description)
			words = this.extractWords(this.selectedChatGptPrompt?.description);
		if(this.selectedMidjourneyPrompt?.description){
			let midWords = this.extractWords(this.selectedMidjourneyPrompt?.description);
			midWords.forEach(el => {
				if(words.indexOf(el) < 0){
					words.push(el);
				}
			});
		}

		if(!this.promptKeys)
			this.promptKeys = [];

		this.promptKeys = this.promptKeys.filter(el => {
			if(words.indexOf(el.key) >= 0){
				return true;
			}
			return false;
		});

		words.forEach(el => {
			if(!this.promptKeys.find(k => k.key === el)){
				this.promptKeys.push({key: el, value: ''});
			}
		});
	}

	extractWords(str: string): string[]{
		const regex = /\$\{([^}]+)\}/g; // Matches content inside ${}
		const matches = [];
		let match;

		// Use regex.exec() to find all matches
		while ((match = regex.exec(str)) !== null) {
			matches.push(match[1].trim()); // Add the captured content to the array
		}

		return matches;
	}

	updateChatGptDescription(): void {
		if (this.selectedChatGptPrompt && this.chatGptLanguage) {
			let description = this.selectedChatGptPrompt.description;
			this.promptKeys.forEach(el => {
				const regex = new RegExp(`\\$\\{${el.key}\\}`, 'g');
				description = description.replace(regex, el.value);
			});
			this.chatGptDescription = description;
		}
	}

	toggleChatGptHashtags(withHashtags: boolean): void {
		if (withHashtags) {
			this.chatGptDescription = `#${this.chatGptDescription}`;
		} else {
			this.chatGptDescription = this.chatGptDescription.replace(/^#/, '');
		}
	}

	copyChatGptPrompt(): void {
		if (this.chatGptDescription) {
			navigator.clipboard.writeText(this.chatGptDescription).then(() => {
				this.message.success('ChatGPT Prompt copied to clipboard.');
			}).catch(() => {
				this.message.error('Failed to copy ChatGPT Prompt.');
			});
		}
	}

	// Midjourney Methods
	selectMidjourneyPrompt(prompt: Prompt): void {
		this.selectedMidjourneyPrompt = prompt;
		this.updatePromptKeys();
		this.updateMidjourneyDescription();
	}

	updateMidjourneyDescription(): void {
		if (this.selectedMidjourneyPrompt && this.midjourneyLanguage) {
			let description = this.selectedMidjourneyPrompt.description;
			this.promptKeys.forEach(el => {
				const regex = new RegExp(`\\$\\{${el.key}\\}`, 'g');
				description = description.replace(regex, el.value);
			});
			this.midjourneyDescription = description;
		}
	}

	copyMidjourneyPrompt(): void {
		if (this.midjourneyDescription) {
			navigator.clipboard.writeText(this.midjourneyDescription).then(() => {
				this.message.success('Midjourney Prompt copied to clipboard.');
			}).catch(() => {
				this.message.error('Failed to copy Midjourney Prompt.');
			});
		}
	}

	// Add Prompt Button Handler
	addPrompt(): void {
		this.resetTopForm();
		this.message.info('Form reset. Enter new link, title, and image link.');
	}
}


