import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './features/home/home.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ApiUrlInterceptor } from './interceptors/api-url.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { fr_FR } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { PromptGeneratorComponent } from './features/prompt-generator/prompt-generator.component';
import { SharedModule } from './shared/shared.module';

registerLocaleData(fr);

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		PromptGeneratorComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		RouterModule,
		AppRoutingModule,
		
		NzLayoutModule,
		NzMenuModule,
		BrowserAnimationsModule,
		IconsProviderModule,

		SharedModule,
	],
	providers: [
		AuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiUrlInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{ provide: NZ_I18N, useValue: fr_FR },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
